import React from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { ArticleQuery } from 'graphql-types';
import {
  ShowIfLargerThanMobile,
  ShowIfMobileOrSmaller,
} from '@components/Shared/SharedStyles';
import { SectionTopLevelWrapper } from '../SectionStyled';
import {
  ArticleIntroWrapper,
  ArticleTitle,
  ArticleIntroImageWrapper,
  ArticleAuthorMinimal,
  ArticleSummary,
  ArticleMetaWrapper,
} from './ArticleIntroSectionStyled';

const ArticleIntroSection: React.FC<ArticleQuery['article']> = (article) => {
  const { titleImage, title, summary, author } = article;
  return (
    <SectionTopLevelWrapper>
      <ArticleIntroWrapper>
        <ArticleTitle>{title.localized}</ArticleTitle>
        <ArticleSummary>{summary.localized}</ArticleSummary>
        <ArticleMetaWrapper>
          <ShowIfLargerThanMobile>
            <ArticleAuthorMinimal>
              by {author.firstName} {author.lastName && ` ${author.lastName}`}
            </ArticleAuthorMinimal>
          </ShowIfLargerThanMobile>
          <ShowIfMobileOrSmaller>
            <ArticleAuthorMinimal>by {author.firstName}</ArticleAuthorMinimal>
          </ShowIfMobileOrSmaller>
        </ArticleMetaWrapper>
        <ArticleIntroImageWrapper>
          {titleImage && (
            <SanityImage
              {...titleImage}
              width={1200}
              className="article-intro-image"
            />
          )}
        </ArticleIntroImageWrapper>
      </ArticleIntroWrapper>
    </SectionTopLevelWrapper>
  );
};

export default ArticleIntroSection;
