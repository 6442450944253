import styled from 'styled-components';
import clampBuilder from '@helpers/clampBuilder';

export const ArticleSummary = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 21px;
  letter-spacing: 0;
  line-height: 31px;
  ${({ theme }) => theme.BreakpointTablet`
    font-size: 19px;
  `};
  ${({ theme }) => theme.BreakpointMobile`
    font-size: 18px;
    line-height: 24px;
  `};
`;

export const ArticleMetaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-self: center;
  padding: 0 20px;
  ${({ theme }) => theme.BreakpointMobile`
    padding: 0 26px 0;
  `};
`;

export const ArticleTitle = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 900;
  letter-spacing: 0;
  text-align: center;
  max-width: 950px;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 20px;
  ${({ theme }) => theme.BreakpointDesktop`
    font-size: ${clampBuilder('BreakpointTablet', 'BreakpointDesktop', 52, 56)};
    line-height: ${clampBuilder(
      'BreakpointTablet',
      'BreakpointDesktop',
      47,
      56
    )};
    margin-bottom: ${clampBuilder(
      'BreakpointTablet',
      'BreakpointDesktop',
      18,
      26
    )}
  `};
  ${({ theme }) => theme.BreakpointTablet`
    margin-bottom: 20px;
    font-size: ${clampBuilder('BreakpointMobile', 'BreakpointTablet', 48, 52)};
    line-height: 52px;
    text-align: center;
  `};
  ${({ theme }) => theme.BreakpointMobile`
    font-size: ${clampBuilder(
      'BreakpointSmallMobile',
      'BreakpointMobile',
      34,
      48
    )};
    line-height: 45px;
  `};
`;

export const ArticleIntroImageWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  .article-intro-image {
    border-radius: 12px;
  }
`;

export const ArticleAuthorMinimal = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.Walsheim.medium};
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0;
  ${({ theme }) => theme.BreakpointMobile`
    font-size: 15px;
    line-height: 11px;
  `};
`;

export const ArticleIntroWrapper = styled.div`
  padding: 20px;
  margin: 0 20px;
  ${({ theme }) => theme.BreakpointMobile`
    padding: 0 20px 0;
  `};
  border-radius: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${ArticleMetaWrapper} {
    margin: 0 auto;
    margin-top: 27px;
    justify-content: center;
    max-width: 950px;
  }
  ${ArticleSummary} {
    text-align: center;
    max-width: 950px;
    font-size: 21px;
    line-height: 32px;
    margin: 0 auto;
  }
`;
