import React, { useEffect } from 'react';
import { graphql, PageProps, Script } from 'gatsby';
import Section from '@components/Sections/Section';
import ArticleIntroSection from '@components/Sections/ArticleIntro/ArticleIntroSection';
import useSaveUtmParamsToLocalStorage from '@src/hooks/useSaveUtmParamsToLocalStorage';
import { HeaderFragmentWithVariant } from '@components/Layout/NavigationBar/interfaces';
import { ArticleQuery, ArticleQueryVariables } from '../../graphql-types';
import PageWrapper from './shared/PageWrapper';
import { analyticsSnippet } from './analytics/analytics';
import trackPage from './analytics/trackPage';

const Article = ({
  data,
  pageContext,
}: PageProps<ArticleQuery, ArticleQueryVariables>) => {
  useSaveUtmParamsToLocalStorage();

  useEffect(() => {
    trackPage({
      categorySlug: data.article?.blog?.slug?.localized,
      pageSlug: data.article?.slug?.localized,
    });
  }, [data.article?.blog?.slug?.localized, data.article?.slug?.localized]);

  return (
    <PageWrapper
      locale={pageContext.locale}
      navigationBar={
        data?.article?.blog?.navigationBar as HeaderFragmentWithVariant
      }
      metadata={data?.article?.metadata}
      announcementId={data?.article?.blog?.navigationBar?.announcement?._id}
      footerBar={data?.sanityFooterBar}
    >
      {data?.article && <ArticleIntroSection {...data.article} />}
      {data.article.content.map((content) => (
        <Section key={content._key} content={content} />
      ))}
    </PageWrapper>
  );
};

export const Head = () => (
  <>
    <Script
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: analyticsSnippet,
      }}
    />
    <Script
      async
      src="https://tag.clearbitscripts.com/v1/pk_54aa5d556dad4b9548cc7054eba8b2ca/tags.js"
      referrerPolicy="strict-origin-when-cross-origin"
    />
  </>
);
export const query = graphql`
  query Article($id: String!, $locale: String!, $footerBarId: String!) {
    sanityFooterBar(_id: { eq: $footerBarId }) {
      ...FooterBar
    }
    article: sanityArticle(_id: { eq: $id }) {
      _id
      _key
      blog {
        navigationBar {
          ...Header
        }
        slug {
          localized(locale: $locale)
        }
      }
      slug {
        localized(locale: $locale)
      }
      title {
        localized(locale: $locale)
      }
      titleImage {
        ...ImageWithCompleteData
      }
      summary {
        localized(locale: $locale)
      }
      author {
        firstName
        lastName
        image {
          ...ImageWithCompleteData
        }
        title
        description {
          localized(locale: $locale)
        }
      }
      category {
        ...BlogCategory
      }
      publishedAt
      content {
        ...ContentSections
      }
      metadata {
        ...SEO
      }
      tracking {
        ...tracking
      }
      redirects
    }
  }
`;

// ts-prune-ignore-next
export default Article;
